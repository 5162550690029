
import { Component, Vue ,Watch} from "vue-property-decorator";
import { AddressService, GoodsOrderService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";
import { log } from "console";

@Component
export default class GoodsDetail extends Vue
{
    
    /**
     * 地址列表
     * @private
     * @returns number
     */
    private addressList: Array<any> = [];

    /**
     * 当前选中地址
     * @private
     * @returns any
     */
    private selectAddressInfo: any =
    {
        receiverName:"",
        phone:"",
        provinceName:"",
        cityName:"",
        areaName:"",
        address:""
    };

    /**
     * 原地址
     * @private
     * @returns any
     */
     private originalAddress: any =
    {
        id:0,
        receiverName:"",
        phone:"",
        provinceName:"",
        cityName:"",
        areaName:"",
        address:""
    };


    /**
     * 订单状态
     * @private
     * @returns string
     */
     private get status(): string
    {
        return this.$route.query && (this.$route.query.status as string) || "";
    }

    /**
     * 订单详情id
     * @private
     * @returns string
     */
     private get id(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 新增收货地址
     * @private
     * @returns 
     */
    protected onAddAddress(): void
    {
        this.$router.push({name: "address-detail"});
    }

    /**
     * 订单地址id
     * @private
     * @returns string
     */
     private get addressId(): string
    {
        return this.$route.query && (this.$route.query.addressId as string) || "";
    }

    /**
     * 编辑收货地址
     * @private
     * @returns
     */
    protected onEdit(id: string): void {
        this.$router.push({ name: "address-detail", query: { id: id } });
    }



    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
     protected created()
    {
        this.getAddressList();
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
     protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 获取地址列表
     * @private
     * @returns void
     */
    private async getAddressList()
    {
        try{
            let {content: result} = await AddressService.instance.getAddressList();

            if(result.data)
            {                
                this.addressList = result.data || [];
                let index = result.data.findIndex(item=>(item.id == this.addressId));
                 
                
                let obj = result.data.find(item=>(item.id == this.addressId));
                
                // 如果只有一个地址 就展示原地址
                if(!this.addressId) {
                    this.originalAddress = result.data[0]
                    return 
                } else {
                    this.originalAddress = obj
                }
                

                if(index!=-1)
                {
                    this.selectAddressInfo  = Object.assign({}, this.addressList[index]);
                    this.addressList.unshift(this.addressList[index]);
                    this.addressList.splice(index+1 ,1);
                } else {
                    // 如果没有
                   const obj = this.addressList.find((item)=> {
                        return item.isDefaultAddress
                    })

                   if(!obj) {
                        this.originalAddress = this.addressList[0]
                   } else {
                        this.originalAddress = obj
                   }
                   

                }
                
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err))
        }
    } 

    /**
     * 提交修改地址
     * @private
     * @returns void
     */
    private async onUpdateAddress()
    {
        if(this.status == "6")
        {
            // 收货状态直接修改地址
            try{
                let {content: result} = await GoodsOrderService.instance.modifyGoodsOrderAddress({
                    orderId: this.id,
                    addressId: this.selectAddressInfo.id
                });

                Toast("修改地址成功");
                this.$router.go(-1);
            }
            catch(err)
            {
                Toast(JSON.stringify(err))
            }
        }
        else
        {
            // 确认订单状态更新地址
            localStorage.setItem("selectAddressInfo", JSON.stringify(this.selectAddressInfo));
            this.$router.go(-1);
        }
       
    } 

    /**
     * 选择地址
     * @private
     * @returns void
     */
    private onSelectAddress(item)
    {
        this.selectAddressInfo = item;

        this.onUpdateAddress();
    } 

}
