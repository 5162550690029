
import { Component, Vue } from "vue-property-decorator";
import { AddressService, GoodsOrderService, RightsService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";

@Component
export default class GoodsDetail extends Vue
{
    /**
     * 数据模型
     * @private
     * @returns any
     */
    private dataModel: any =
    {
        address: "",
        addressId:"",
        marketPrice: "",
        phone: "",
        price: null,
        productId: null,
        productName: "", 
        productPict:  "",
        productQty:  null,
        productTypeStr:"",
        receiverName: "",
        supplierId: null,
        supplierLogo:"",
        supplierName: "",
        unitMarketPrice: null,
        unitPrice: null
    };

    /**
     * 卡片信息
     * @private
     * @returns any
     */
    private cardInfo: any = {
        orderId: 0,
        rightsMemberName: "",
        memberId: 0,
        memberName: "",
        phone: "",
        cardNo: "",
        rightsId: 0,
        rightsName: "",
        backgroundColor: "",
        rightsDesc: "",
        activedLimitTime: "",
        activedLimitTimeStr: "",
        activedEffTime: "",
        activedEffTimeStr: ""
    };

    /**
     * 商品数量
     * @private
     * @returns number
     */
    protected goodsNum: number = 1;

    /**
     * 商品详情id
     * @private
     * @returns string
     */
    private get id(): string
    {
        return this.$route.query && (this.$route.query.id as string) || "";
    }

    /**
     * 权益id
     * @private
     * @returns string
     */
    private get rightsId(): string
    {
        return this.$route.query && (this.$route.query.rightsId as string) || "";
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 商品总价格
     * @private
     * @returns number
     */
    private get sumPrice(): number
    {
        return this.goodsNum * this.dataModel.unitPrice || 0
    }

    /**
     * 地址列表
     * @private
     * @returns number
     */
     private addressList: Array<any> = [];

    /**
     * 修改地址
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
     private async onUpdateAddress(): Promise<void>
    {
        
        if(!this.addressList.length) {
                // 传入当前订单的id
                this.$router.push({name: "address-detail",query:{orderId: this.id}});
            } else {
                this.$router.push({name:"goods-order-update-address", query:{id: this.id, addressId: this.dataModel.addressId, status: this.dataModel.status}});
        }
    }

    /**
     * 组件创建钩子
     * @protected
     * @returns void
     */
     protected created()
    {
        this.getAddressList();
        this.$nextTick(() => {
            if (this.rightsId)
            {
                this.getCardByMemberRightId();
            }
        })
    }

    /**
     * 获取订单列表
     * @private
     * @returns void
     */
    private async getCardByMemberRightId(): Promise<void>
    {
        try
        {
            let { content: result } = await RightsService.instance.clientGetCardByMemberRightId(this.rightsId);
            if(result.data)
            {
                this.cardInfo = result.data;
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 获取地址列表
     * @private
     * @returns void
     */
     private async getAddressList()
    {
        try{
           
            let {content: result} = await AddressService.instance.getAddressList();

            if(result.data)
            {
                this.addressList = result.data || [];
            }

            this.getPreGoodsOrderInfo(this.id);
        }
        catch(err)
        {
            Toast(JSON.stringify(err))
        }
     } 

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
           location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }

    /**
     * 获取产品下单前数据
     * @public
     * @param {params}
     * @returns {Promise<IHttpResponse>}
     */
    public async getPreGoodsOrderInfo(id: string): Promise<void>
    {
        try
        {
            let {content: result} = await GoodsOrderService.instance.getPreGoodsOrderInfo(id);

            if(result.data)
            {
                this.dataModel = result.data;
            }

            // 判断是否有地址数据
            if(!result.data.addressId && this.addressList.length) {
                const obj = this.addressList.find((item)=> {
                    return !item.isDefaultAddress
                })
                if(obj) {
                    this.dataModel.address = obj.address;
                    this.dataModel.receiverName = obj.receiverName;
                    this.dataModel.phone = obj.phone;
                    this.dataModel.addressId = obj.id;
                } else {
                    this.dataModel.address = this.addressList[0].address;
                    this.dataModel.receiverName = this.addressList[0].receiverName;
                    this.dataModel.phone = this.addressList[0].phone;
                    this.dataModel.addressId = this.addressList[0].id;
                }

            }

            let selectAddressInfoStorage = localStorage.getItem("selectAddressInfo");

            if(selectAddressInfoStorage)
            {
                let selectAddressInfo = JSON.parse(selectAddressInfoStorage);
                this.dataModel.address = selectAddressInfo.address;
                this.dataModel.receiverName = selectAddressInfo.receiverName;
                this.dataModel.phone = selectAddressInfo.phone;
                this.dataModel.addressId = selectAddressInfo.id;
                localStorage.removeItem("selectAddressInfo");
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }

    }

    /**
     * 提交订单
     * @public
     * @param {params}
     */
    public async onSubmit(id: string): Promise<void>
    {
        try
        {
            if(!this.dataModel.addressId)
            {
                Toast("请先选择收货地址!");
                return;
            }
            let data = {
                productId: this.dataModel.productId,
                addressId: this.dataModel.addressId,
                productQty: this.goodsNum,
                rightsId: this.rightsId
            }
            let {content: result} = await GoodsOrderService.instance.createGoodsOrder(data);
            
            if(result.data)
            {

                if(result.data.isNeedPay)
                {
                    this.$router.push({name: "pay", query:{orderNo: result.data.orderNo}});
                    // // 需要支付
                    // Pay.onBridgeReady(result.data,
                    //     ()=> this.onSucessDetail(result.data.orderId), 
                    //     ()=> this.onActionOrderDetail(result.data.orderId)
                    // )
                }
                else
                {
                    this.$router.push({name: "goods-order-sucess", query:{id: result.data.orderId, rightsId: this.rightsId}});
                }
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }

    }

    /**
     * 跳转成功页面
     * @private
     * @returns void
     */
     private onSucessDetail(id: string): void
    {
        this.$router.push({name: "goods-order-sucess", query:{id: id}});
    }

    /**
     * 跳转订单明细
     * @private
     * @returns void
     */
    private onActionOrderDetail(id: string): void
    {
        this.$router.push({name: "goods-order-detail", query:{id: id}});
    }

}
